import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const FALLBACK = 'en';

i18n
  .use(LanguageDetector)
  .init({
    resources: {
      es: {
        translations: {
          Jan: 'Ene',
          Feb: 'Feb',
          Mar: 'Mar',
          Apr: 'Abr',
          May: 'Mayo',
          June: 'Jun',
          July: 'Jul',
          Aug: 'Ago',
          Sept: 'Set',
          Oct: 'Oct',
          Nov: 'Nov',
          Dec: 'Dic',
          Location: 'Localización',
          Loading: 'cargando',
          Population: 'población',
          'COVID-19 Vaccination': 'Vacunación COVID-19',
          'Doses Given': 'Dosis administradas',
          'people with at least 1 dose': 'personas con al menos 1 dosis',
          'people fully vaccinated': 'personas completamente vacunadas',
          'vaccination over the time': 'vacunación a lo largo del tiempo',
          '% at least 1 dose': 'at least 1 dose %',
          '% fully vaccinated': 'completamente vacunado %',
          'powered by': 'Desarrollado por',
          'Last Updated': 'Última Actualización',
        },
      },
      de: {
        translations: {
          Jan: 'Jan',
          Feb: 'Fev',
          Mar: 'März',
          Apr: 'Abr',
          May: 'Mai',
          June: 'Juni',
          July: 'Juli',
          Aug: 'Aug',
          Sept: 'Sept',
          Oct: 'Okt',
          Nov: 'Nov',
          Dec: 'Dez',
          Location: 'Platz',
          Loading: 'Wird geladen',
          Population: 'Bevölkerung',
          'COVID-19 Vaccination': 'COVID-19-Impfung',
          'Doses Given': 'Dosierungen gegeben',
          'people with at least 1 dose': 'Menschen mit mindestens 1 Dosis',
          'people fully vaccinated': 'Menschen voll geimpft',
          'vaccination over the time': 'Impfung im Laufe der Zeit',
          '% at least 1 dose': 'mindestens 1 Dosis %',
          '% fully vaccinated': 'voll geimpft %',
          'powered by': 'unterstützt von',
          'Last Updated': 'Letzte Aktualisierung',
        },
      },
      nl: {
        translations: {
          Jan: 'Genn',
          Feb: 'Febbr',
          Mar: 'Maart',
          Apr: 'Apr',
          May: 'Mei',
          June: 'Juni',
          July: 'Juli',
          Aug: 'Aug',
          Sept: 'Sept',
          Oct: 'Okt',
          Nov: 'Nov',
          Dec: 'Dec',
          Location: 'Plaats',
          Population: 'bevolking',
          'COVID-19 Vaccination': 'COVID-19-vaccinatie',
          'Doses Given': 'Gegeven doses',
          'people with at least 1 dose': 'mensen met minstens 1 dosis',
          'people fully vaccinated': 'mensen volledig ingeënt',
          'vaccination over the time': 'vaccinatie in de loop van de tijd',
          '% at least 1 dose': 'minstens 1 dosis %',
          '% fully vaccinated': 'volledig gevaccineerd %',
          'powered by': 'aangedreven door',
          'Last Updated': 'Laatst Bijgewerkt',
        },
      },
      it: {
        translations: {
          Jan: 'Genn',
          Feb: 'Febbr',
          Mar: 'Mar',
          Apr: 'Apr',
          May: 'Magg',
          June: 'Giugno',
          July: 'Luglio',
          Aug: 'Ag',
          Sept: 'Sett',
          Oct: 'Ott',
          Nov: 'Nov',
          Dec: 'Dic',
          Location: 'Posto',
          Population: 'popolazione',
          'COVID-19 Vaccination': 'Vaccinazione COVID-19',
          'Doses Given': 'Dosi somministrate',
          'people with at least 1 dose': 'persone con almeno 1 dose',
          'people fully vaccinated': 'persone completamente vaccinate',
          'vaccination over the time': 'vaccinazione nel tempo',
          '% at least 1 dose': 'almeno 1 dose %',
          '% fully vaccinated': 'completamente vaccinato %',
          'powered by': 'offerto da',
          'Last Updated': 'Ultimo Aggiornamento',
        },
      },
      pt: {
        translations: {
          Jan: 'Jan',
          Feb: 'Fev',
          Mar: 'Mar',
          Apr: 'Abr',
          May: 'Maio',
          June: 'Jun',
          July: 'Jul',
          Aug: 'Ago',
          Sept: 'Set',
          Oct: 'Out',
          Nov: 'Nov',
          Dec: 'Dez',
          Location: 'Localização',
          Loading: 'Carregando',
          Population: 'População',
          'COVID-19 Vaccination': 'Vacinação COVID-19',
          'Doses Given': 'Doses dadas',
          'people with at least 1 dose': 'pessoas com pelo menos 1 dose',
          'people fully vaccinated': 'pessoas totalmente vacinadas',
          'vaccination over the time': 'vacinação ao longo do tempo',
          '% at least 1 dose': 'pelo menos 1 dose %',
          '% fully vaccinated': 'totalmente vacinados %',
          'powered by': 'distribuído por',
          'Last Updated': 'Última Atualização',
        },
      },
      fr: {
        translations: {
          Jan: 'Janv',
          Feb: 'Févr',
          Mar: 'Mars',
          Apr: 'Avril',
          May: 'Mai',
          June: 'Juin',
          July: 'Juil',
          Aug: 'Août',
          Sept: 'Sept',
          Oct: 'Oct',
          Nov: 'Nov',
          Dec: 'Déc',
          Location: 'Emplacement',
          Loading: 'Chargement en cours',
          Population: 'Population',
          'COVID-19 Vaccination': 'Vaccination COVID-19',
          'Doses Given': 'Doses Administrées',
          'people with at least 1 dose': 'personnes avec au moins 1 dose',
          'people fully vaccinated': 'personnes complètement vaccinées',
          'vaccination over the time': 'vaccination au fil du temps',
          '% at least 1 dose': '% au moins 1 dose',
          '% fully vaccinated': '% complètement vaccinés',
          'powered by': 'alimenté par',
          'Last Updated': 'Dernière mise à jour',
        },
      },
    },
    fallbackLng: FALLBACK,
    debug: true,

    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export default i18n;
